<template>
  <div class="auth-wrap">
    <h4>Welcome!</h4>

    <van-field v-model="loginData.username" placeholder="Account"></van-field>
    <div>
      <van-field v-model="loginData.password" :type="passwordInputType" placeholder="Password"
                 :right-icon="iconShowPassword" @click-right-icon="onShowHidePassword"></van-field>
    </div>


    <van-button @click="onLoginClick" class="auth-button">Login</van-button>
  </div>
</template>

<script>
import auth from "@/api/auth";
import {Toast} from "vant";

export default {
  name: "Login",
  data() {
    return {
      loginData: {
        username: '',
        password: ''
      },
      passwordInputType: 'password',
      showPassword: false,
      iconShowPassword: require('@/assets/logo/show-password.png')
    }
  },
  methods: {
    onShowHidePassword() {
      this.showPassword = !this.showPassword
      if (this.showPassword) {
          this.passwordInputType = 'text'
      }else {
        this.passwordInputType = 'password'
      }
    },
    async onLoginClick() {
      try {
        this.$store.dispatch("login",Object.assign({},this.loginData))
      }catch (e) {

      }

    }
  }
}
</script>

<style scoped lang="less">
.auth-wrap {
  padding: 15px;
}

::v-deep .van-cell {
  margin-top: 10px;
  background: #F8F8F8;
  border-radius: 10px;
  color: #BBBBBB;
}

.auth-button {
  width: 100%;
  border-radius: 8px;
  color: white;
  background: #0075FF;
}
</style>